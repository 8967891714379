import React from "react"
import { useRouteMatch, Link } from "react-router-dom";

export default function MenuLink(props) {
  let match = useRouteMatch({
    path: props.to,
    exact: false
  });
	
  return (
    <Link to={props.to} className={match ? "active" : ""}>{props.children}</Link>
  );
}
