import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory, useLocation } from "react-router-dom";
import logo from './logo.svg';
import Cookies from 'js-cookie';
import MenuLink from './MenuLink';
import UserSearch from './UserSearch';
import './app.scss';

function App() {
  const [signedIn, setSignedIn]=useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  
  function handleSubmit(e) {
    e.preventDefault();
    
    if (isFormDisabled() === true) {
      return;
    }
    
    signin();
  }
  
  const signin=()=>{
    fetch('/api/login',{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({email: email, password: password})
    })
    .then(response => response.json())
    .then(json => {
      console.log('Signin data:', json);
      
      if (
        json.hasOwnProperty('signin')
        && json.signin > 0
      ) {
        setSignedIn(true);
        setEmail('');
        setPassword('');
      }
      
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
  
  const getData=()=>{
    fetch('/api/ping',{
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(json => {
      console.log('200');
      
      if (
        json.signedIn !== signedIn
      ) {
        setSignedIn(json.signedIn);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
  
  useEffect(()=>{
    getData()
  });
  
  const runInterval = () => {
    getData();
  };
  
  useEffect(() => {
    const interval = setInterval(() => {
      console.log('interval is set');
      runInterval();
    }, 5000);
  
    return () => {
      clearInterval(interval);
    };
  });  
  
  function isFormDisabled() {
    if (email.length === 0) {
      return true;
    }
    
    if (password.length < 6) {
      return true;
    }
    
    return false;
  }
  
  if (signedIn === false) {
    return (
      <section>
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <label>
            E-mailadres
            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
          </label>
          <label>
            Wachtwoord
            <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
          </label>
          <button type="submit" disabled={isFormDisabled()}>Submit</button>
        </form>
      </section>
    );
  }
  
  return (
    
    <Router>
      <header>
        <nav>
          <ul>
            <li>
              <MenuLink to="/">Dashboard</MenuLink>
            </li>
            <li>
              <MenuLink to="/nieuwe-aanmeldingen">Nieuwe aanmeldingen</MenuLink>
            </li>
            <li>
              <MenuLink to="/gebruiker">Gebruikers</MenuLink>
            </li>
            <li>
              <MenuLink to="/account">Accounts</MenuLink>
            </li>
          </ul>
        </nav>
      </header>
      <Switch>
        <Route exact path="/nieuwe-aanmeldingen">
          <h1>Nieuwe aanmeldingen</h1>
        </Route>
        <Route exact path="/gebruiker" component={UserSearch} />
        <Route exact path="/account">
          <h1>Account</h1>
        </Route>
        <Route exact path="/">
          <h1>Dashboard</h1>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
