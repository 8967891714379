import React, { useState } from "react";

export default function User({ user }) {
  const [change, setChange] = useState(false);
  
  const toggleChange = () => {
	  setChange(!change);
  }

  if (user === null) {
	return null;
  }
  
  let stringEmailStatus = '[E-mailadres]';
  
  if ((user.status & 1) === 0) {
	stringEmailStatus = 'Geen e-mailadres ingesteld';
  }
  
  if ((user.status & 2) === 0) {
    stringEmailStatus = '[E-mailadres], niet geverifieerd';
  }
  
  let stringPasswordStatus = 'Geen wachtwoord ingesteld';
  
  if (user.passwordSet === true) {
	stringPasswordStatus = 'Wachtwoord ingesteld';
  }
	
  return (
	<div className="user">
	  <h2>{user.nameFirst}</h2>
	  <button onClick={toggleChange}>Bewerk</button>
	  <dl>
	    <dt>E-mailadres</dt>
		<dd>{stringEmailStatus}</dd>
	  </dl>
	  <dl>
		<dt>Wachtwoord</dt>
		<dd>{stringPasswordStatus}</dd>
	  </dl>
	</div>
  );
}
