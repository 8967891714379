import React, { useState } from "react";
import WarningNotice from './warningNotice'
import User from './User'

export default function UserSearch(props) {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [warning, setWarning] = useState({title: '', message: ''});
  
  function onEmailChange(value) {
    setEmail(value);
    setUser(null);
  }
  
  function handleSubmit(e) {
    e.preventDefault();
    
    if (isFormDisabled() === true) {
      return;
    }
    
    search();
  }
  
  const search=()=>{
    fetch('/api/user?search=' + email,{
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(json => {
      console.log('Success:', json);
      
      if (
        json.success === false
        && json.hasOwnProperty('title')
        && json.hasOwnProperty('message')
      ) {
        alert(json.message);
        
        setWarning({title: json.title, message: json.message});
      }
      
      if (json.success === true) {
        setWarning({title: '', message: ''});
        setUser(json.user);
      }
      
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
  
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  function isFormDisabled() {
    if (email.length === 0 || validateEmail(email) === false) {
      return true;
    }
    
    return false;
  }
  
  return (
    <section>
      <h1>Zoek gebruiker</h1>
      <form onSubmit={handleSubmit}>
        <label>
          E-mailadres
          <input type='email' value={email} onChange={(e) => onEmailChange(e.target.value)} />
        </label>
        <button type="submit" disabled={isFormDisabled()}>Zoek</button>
      </form>
      <WarningNotice title={warning.title} message={warning.message} />
      <hr />
      <User user={user} />
    </section>
  );
}
