import React from "react";

export default function WarningNotice({ title, message }) {
  if (title.length === 0 && message.length === 0) {
    return null;
  }

  return (
    <div className="warning">
	  <h2>{title}</h2>
      <p>{message}</p>
    </div>
  );
}